





























































































































































import BaseToolbar from '@/components/core/BaseToolbar.vue';
import { Component } from 'vue-property-decorator';
import SearchFilter from '@/components/core/SearchFilter.vue';

@Component({
  components: {
    BaseToolbar,
    SearchFilter
  }
})
export default class ClassSeatingToolbar extends BaseToolbar {
  searchWidthGrow = '0';

  $refs!: {
    searchFilter: SearchFilter
  }

  get localComponent(): any {
    return this.currentPageInstance || {};
  }

  get editorOpen(): boolean {
    return this.localComponent.editorOpen || false;
  }

  get addSeating() {
    return this.localComponent.addSeating || this.emptyFunction;
  }

  get toggleSeatingTableEdit() {
    return this.localComponent.toggleSeatingTableEdit || this.emptyFunction;
  }

  get confirmDeleteMultipleSeating() {
    return (
      this.localComponent.confirmDeleteMultipleSeating || this.emptyFunction
    );
  }

  get clearFilter() {
    return this.localComponent.clearFilter || this.emptyFunction;
  }

  get seatingTableEdit() {
    return this.localComponent.seatingTableEdit;
  }

  get isSelectedSeatingEmpty() {
    return this.localComponent.isSelectedSeatingEmpty;
  }

  get filterSelections() {
    return this.localComponent.filterSelections || {};
  }

  get filterOptions() {
    return this.localComponent.filterOptions || {
      classList: [],
      layoutList: []
    };
  }

  get applyFilter() {
    return this.localComponent.applyFilter || {};
  }

  get seatingSearch() {
    return this.localComponent.seatingSearch || '';
  }

  set seatingSearch(value: string) {
    this.localComponent.seatingSearch = value;
  }

  get localClassList() {
    return this.filterOptions.classList || [];
  }

  get localLayoutList() {
    return this.filterOptions.layoutList || [];
  }

  get isFiltered() {
    if (this.seatingSearch === '' && this.filterSelections.selectedClass === this.localClassList[0] && this.filterSelections.selectedLayout === this.localLayoutList[0]) {
      this.searchWidthGrow = '0';
      return false;
    } else {
      this.searchWidthGrow = '40';
      return true;
    }
  }

  get filterCount() {
    var count = 0;
    if (this.seatingSearch !== '') {
      count += 1;
    }
    if (this.filterSelections.selectedClass !== this.localClassList[0]) {
      count += 1;
    }
    if (this.filterSelections.selectedLayout !== this.localLayoutList[0]) {
      count += 1;
    }
    return count;
  }

  focusMenu() {
    this.$nextTick(() => {
      new Promise(resolve => setTimeout(resolve, 100)).then(() => {
        try {
          (this.$refs as any).firstMenuItem.$el.querySelector('input').focus();
        } catch (e) {
          console.log(this.$t('focusFailed'));
        }
      });
    });
  }

  focusButton() {
    this.$refs.searchFilter.focusButton();
  }
}
